:root {
    --white: #ffffff;
    --gray1: #f4f4f8;
    --gray2: #e6e6ea;
    --gray3: #b1b1b4;
    --gray4: #979797;
    --gray5: #737375;
    --gray6: #4f4f50;
    --gray7: #39393a;
    --gray8: #232324;
    --gray9: #141414;
    --black: #000000;

    --red1: red;
    --red2: rgb(179, 0, 0);
    --red3: rgb(129, 0, 0);
    --red4: rgb(58, 0, 0);

    --orange1: #ff6c00;
    --orange2: #b1461f;
    --orange3: #bb7900;
    --orange4: #ed9a00;

    --green1: rgb(0, 255, 0);
    --green2: rgb(0, 180, 0);
    --green3: green;
    --green4: rgb(0, 59, 0);

    --highlight-blue-1: rgb(205, 205, 255);
    --highlight-blue-2: rgb(182, 182, 255);
    --highlight-blue-3: rgb(58, 58, 185);

    --blue1: rgb(0, 162, 255);
    --blue2: blue;
    --blue3: rgb(0, 0, 182);
    --blue4: rgb(0, 0, 133);
    --blue5: rgb(0, 0, 66);

    --push-height: 500px;
    --short-push-height: 175px;
}

/* .light-theme {
    --background: var(--white);
    --text: var(--black);
    --secondary-text: var(--gray6);
    --nav-menu-item-highlight: var(--highlight-blue-1);
} */

.dark-theme {
    --background: var(--gray8);
    --background-light: var(--gray7);
    --highlight: var(--gray6);
    --soft-highlight: var(--gray7);
    --shadow: var(--gray9);
    --text: var(--white);
    --text-inverted: var(--black);
    --secondary-text: var(--gray3);
    --nav-menu-item-highlight: var(--white);
    --link-blue: var(--blue1);
    --orange-text: var(--orange1);
    --blue-text: var(--blue1);
    --red-text: var(--red1);
    --text-red-desaturated: rgb(220, 50, 50);
    --text-blue-desaturated: rgb(50, 50, 220);
    --text-green-desaturated: rgb(50, 220, 50);
    --text-orange-desaturated: rgb(255, 110, 0);

    --info: var(--blue1);
    --error: var(--red1);
    --success: var(--green1);
    --warning: orange;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

html {
    max-width: 100%;
    font-size: 18px;
}

hr {
    background-color: white;
    margin: 1rem 0;
    background-color: white;
    opacity: 0.2;
}

* {
    font-family: "Times New Roman", Times, serif;
    color: var(--text);
}

.nav-card-cluster {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.orange-text {
    color: var(--orange1);
}

.blue-text {
    color: var(--blue1);
}

input {
    color: var(--text-inverted);
    padding: 2px 5px;
    font-size: 1rem;
}

input.large {
    font-size: 1.3rem;
}

select {
    background-color: var(--text);
    color: var(--text-inverted);
    padding: 2px 5px;
    font-size: 1rem;
}

select.large {
    font-size: 1.3rem;
}

select option {
    background-color: var(--text);
    color: var(--text-inverted);
}

.full-width {
    width: 100%;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    box-shadow: 0 0 20px var(--shadow);
}

table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    margin: 0;
}

table .no-data-row {
    text-align: center;
    padding: 10px 0;
}

table thead tr {
    background-color: var(--gray6);
    text-align: left;
}

table th,
table td {
    padding: 6px 7px;
}

table tbody tr:nth-of-type(even) {
    background-color: var(--gray7);
}

table tbody tr:nth-of-type(odd) {
    background-color: var(--background);
}

table tbody tr:last-of-type {
    border-bottom: 5px solid var(--gray6);
}

.secondary-text {
    color: var(--secondary-text);
}

.red-text {
    color: var(--red1);
}

.link {
    color: var(--link-blue);
}

.clickable-icon {
    cursor: pointer;
    fill: var(--gray3);
}

.clickable-icon:hover {
    fill: var(--gray1);
}

.icon-disabled {
    fill: var(--gray5);
}

.text-red-desaturated {
    color: var(--text-red-desaturated);
}

.text-blue-desaturated {
    color: var(--text-blue-desaturated);
}

.text-green-desaturated {
    color: var(--text-green-desaturated);
}

.drop-shadow {
    filter: drop-shadow(0 0 5px var(--shadow));
}

.inverted-select {
    background-color: var(--bg);
    color: var(--text);
}

.inverted-select option {
    background-color: var(--bg);
    color: var(--text);
}

.inverted-select:hover {
    background-color: var(--background-light);
}

.inverted-input {
    background-color: var(--bg);
    color: var(--text);
}

.input-label {
    display: flex;
    align-items: center;
    gap: 5px;
}

.verify-button {
    border-color: var(--orange-text) !important;
}

.verify-button > span {
    color: var(--orange-text) !important;
}

.verify-button:hover {
    box-shadow: 0px 0px 5px 0px var(--orange-text) !important;
}

.transparent-input {
    padding: 0 5px;
    background-color: transparent;
    border: none;
    color: var(--text);
    box-sizing: border-box;
    outline: none;
}

/* mobile */
@media (max-width: 768px) {
    html {
        font-size: 15px;
    }

    .hide-on-mobile {
        display: none !important;
    }

    .full-width-mobile {
        width: 100%;
    }

    ul {
        padding-left: 20px;
    }

    .shrinkable-icon {
        width: 20px;
        height: 20px;
    }

    input {
        min-width: 18px;
        min-height: 18px;
    }

    .input-label {
        font-size: 1.2rem;
    }
}

/* small mobile */
@media (max-width: 300px) {
    html {
        font-size: 12px;
    }

    .hide-on-small-mobile {
        display: none !important;
    }
}
