.server-navigation-card {
    background-color: var(--background);
    text-decoration: none;
    border: none;
    padding: 0 20px;
    border-radius: 5px;
    cursor: pointer;
    transition:
        background-color 200ms,
        filter 200ms;
    width: 320px;
    height: 6rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    font-size: 1rem;

    &.miniature {
        height: auto !important;
        padding: 10px 20px;
    }

    &.full-width {
        width: 100% !important;
    }
}

.server-navigation-card:hover {
    background-color: var(--highlight);
    filter: drop-shadow(2px 2px 2px var(--shadow));
}

.server-navigation-card-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin: 0;
    font-size: 1.3rem;
    font-weight: 400;
}

.server-navigation-card-content {
    text-align: left;
    color: var(--secondary-text);
    margin: 0;
    /* font-size: 1rem; */
    line-height: 1.3;
}

@media (max-width: 1140px) {
    .server-navigation-card {
        width: 200px;
        height: 5rem;
    }
}

@media (max-width: 768px) {
    .server-navigation-card {
        width: 100%;
        padding: 8px 10px;
        height: unset;
    }

    .server-navigation-card-title {
        font-size: 1.1rem;
    }

    .server-navigation-card-content {
        line-height: 1rem;
    }

    .server-navigation-card .icon {
        width: 20px;
        height: 20px;
    }
}
