.filter-section {
    margin-left: 5px;
    padding-left: 10px;
    border-left: 2px solid var(--secondary-text);
}

.filter-section.multi-select {
    border-left: none;
    padding: 10px 10px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 140px;
    width: 100%;
    box-shadow: inset 0 0 20px var(--shadow);
}

.lfm-settings-modal {
    padding: 20px;
}
